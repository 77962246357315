// Generated by Framer (8107e78)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCodeBoundaryForOverrides, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import { withCopyLinkOnClick } from "https://framerusercontent.com/modules/yp9O5IvnYjXI5ReAXFdi/mHIh4KuuYoJ6IbwT72Lv/Copylink.js";
const PhosphorFonts = getFonts(Phosphor);
const MotionDivWithCopyLinkOnClick129y29f = withCodeBoundaryForOverrides(motion.div, {nodeId: "vRb4hLne2", override: withCopyLinkOnClick, scopeId: "Y5umyuIPp"});

const enabledGestures = {vRb4hLne2: {hover: true}};

const cycleOrder = ["vRb4hLne2", "TWDSTjovZ"];

const serializationHash = "framer-ItWhs"

const variantClassNames = {TWDSTjovZ: "framer-v-kwccnb", vRb4hLne2: "framer-v-129y29f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "vRb4hLne2", "Variant 2": "TWDSTjovZ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "vRb4hLne2"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vRb4hLne2", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaph94o9n = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("TWDSTjovZ")
})

const onAppearttw96d = activeVariantCallback(async (...args) => {
await delay(() => setVariant("vRb4hLne2"), 700)
})

useOnVariantChange(baseVariant, {TWDSTjovZ: onAppearttw96d})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const isDisplayed = () => {
if (gestureVariant === "vRb4hLne2-hover") return true
return false
}

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithCopyLinkOnClick129y29f {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-129y29f", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"vRb4hLne2"} onTap={onTaph94o9n} ref={refBinding} style={{backgroundColor: "var(--token-e21d78c6-9a46-4cbd-aadc-ebd33ec712f0, rgb(237, 238, 240))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}} {...addPropertyOverrides({"vRb4hLne2-hover": {"data-framer-name": undefined}, TWDSTjovZ: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1ji9zu6-container"} isModuleExternal layoutDependency={layoutDependency} layoutId={"Ji0E6J4Bb-container"} nodeId={"Ji0E6J4Bb"} rendersWithMotion scopeId={"Y5umyuIPp"}><Phosphor color={"var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(23, 23, 23))"} height={"100%"} iconSearch={"House"} iconSelection={"Copy"} id={"Ji0E6J4Bb"} layoutId={"Ji0E6J4Bb"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"duotone"} width={"100%"} {...addPropertyOverrides({TWDSTjovZ: {color: "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(111, 68, 255))", iconSelection: "CheckFat"}}, baseVariant, gestureVariant)}/></SmartComponentScopedContainer></ComponentViewportProvider>{isDisplayed() && (<motion.div className={"framer-6xr8gl"} layoutDependency={layoutDependency} layoutId={"JPaOIVFvT"} style={{backgroundColor: "rgba(184, 184, 184, 0.03)"}}/>)}</MotionDivWithCopyLinkOnClick129y29f></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ItWhs.framer-1yykdsx, .framer-ItWhs .framer-1yykdsx { display: block; }", ".framer-ItWhs.framer-129y29f { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 10px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-ItWhs .framer-1ji9zu6-container { flex: none; height: 20px; position: relative; width: 20px; }", ".framer-ItWhs .framer-6xr8gl { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ItWhs.framer-129y29f { gap: 0px; } .framer-ItWhs.framer-129y29f > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-ItWhs.framer-129y29f > :first-child { margin-left: 0px; } .framer-ItWhs.framer-129y29f > :last-child { margin-right: 0px; } }", ".framer-ItWhs.framer-v-129y29f.hover .framer-1ji9zu6-container { order: 1; }", ".framer-ItWhs.framer-v-129y29f.hover .framer-6xr8gl { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"TWDSTjovZ":{"layout":["auto","auto"]},"w7hfed5Bl":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerY5umyuIPp: React.ComponentType<Props> = withCSS(Component, css, "framer-ItWhs") as typeof Component;
export default FramerY5umyuIPp;

FramerY5umyuIPp.displayName = "Button/Copy";

FramerY5umyuIPp.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerY5umyuIPp, {variant: {options: ["vRb4hLne2", "TWDSTjovZ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerY5umyuIPp, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})